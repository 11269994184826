import React from 'react'
import { Link } from 'react-router-dom'
import {
	FaFacebookF,
	FaTwitter,
	FaLinkedin,
	FaInstagram,
	FaTiktok,
	FaGithub,
} from 'react-icons/fa'
import ServiceData from '../../data/service/ServiceMain.json'
import BlogData from '../../data/blog/BlogData.json'
import { slugify } from '../../utils'

const getServiceData = ServiceData
const getBlogData = BlogData

const FooterOne = ({ parentClass }) => {
	const openLinkInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) {
			newWindow.opener = null
		}
	}
	return (
		<footer className={`footer-area ${parentClass}`}>
			<div className='container'>
				<div className='footer-top'>
					<div className='footer-social-link'>
						<ul className='list-unstyled'>
							<li>
								<FaFacebookF
									onClick={() =>
										openLinkInNewTab(
											'https://web.facebook.com/zusetechnologies'
										)
									}
								/>
							</li>
							<li>
								<FaTwitter
									onClick={() => openLinkInNewTab('https://twitter.com/')}
								/>
							</li>
							<li>
								<FaTiktok
									onClick={() =>
										openLinkInNewTab('https://www.tiktok.com/@zusetech')
									}
								/>
							</li>
							<li>
								<FaLinkedin
									onClick={() =>
										openLinkInNewTab(
											'https://www.linkedin.com/company/zusetechnologies/'
										)
									}
								/>
							</li>
							<li>
								<FaInstagram
									onClick={() =>
										openLinkInNewTab(
											'https://www.instagram.com/zusetechnologies/?hl=en'
										)
									}
								/>
							</li>
							<li>
								<FaGithub
									onClick={() =>
										openLinkInNewTab('https://github.com/Zuse-Technologies/')
									}
								/>
							</li>
						</ul>
					</div>
				</div>
				<div className='footer-main'>
					<div className='row'>
						<div className='col-xl-6 col-lg-5'>
							<div className='footer-widget border-end'>
								<div className='footer-newsletter'>
									<h2 className='title'>Get in touch!</h2>
									<p>
										Our team is available to discuss your project requirements
										and provide you with a personalized solution that exceeds
										your expectations. Get in touch with us today!
									</p>
								</div>
							</div>
						</div>
						<div className='col-xl-6 col-lg-7'>
							<div className='row'>
								<div className='col-sm-4'>
									<div className='footer-widget'>
										<h6 className='widget-title'>Services</h6>
										<div className='footer-menu-link'>
											<ul className='list-unstyled'>
												{getServiceData.slice(0, 3).map((data, index) => (
													<li key={index}>
														<Link
															to={
																process.env.PUBLIC_URL +
																`/service-details/${slugify(data.title)}`
															}
														>
															{data.title}
														</Link>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
								<div className='col-sm-4'>
									<div className='footer-widget'>
										<h6 className='widget-title'>Products</h6>
										<div className='footer-menu-link'>
											<ul className='list-unstyled'>
												{getBlogData.slice(0, 2).map((data, index) => (
													<li key={index}>
														<Link
															to={
																process.env.PUBLIC_URL +
																`/products/${slugify(data.id)}`
															}
														>
															{data.title}
														</Link>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
								<div className='col-sm-4'>
									<div className='footer-widget'>
										<h6 className='widget-title'>Support</h6>
										<div className='footer-menu-link'>
											<ul className='list-unstyled'>
												<li>
													<Link to={process.env.PUBLIC_URL + '/contact'}>
														Contact Us
													</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='footer-bottom'>
					<div className='row'>
						<div className='col-md-6'>
							<div className='footer-copyright'>
								<span className='copyright-text'>
									© {new Date().getFullYear()}. All rights reserved by{' '}
									<a href='https://zuse.lk/'>Zuse Technologies (PVT)</a> Ltd
								</span>
							</div>
						</div>
						<div className='col-md-6'>
							<div className='footer-bottom-link'>
								<ul className='list-unstyled'>
									<li>
										We Make{' '}
										<b>
											<i>IT</i>
										</b>{' '}
										Happen
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default FooterOne
