import React from 'react';

const Data = [
    {
        image: "/images/zuse_brands/lyceum.jpg"
    },
    {
        image: "/images/zuse_brands/dteam.jpg"
    },
    {
        image: "/images/zuse_brands/bookstudio.jpg"
    },
    {
        image: "/images/zuse_brands/lyc.jpg"
    },
    {
        image: "/images/zuse_brands/bitrock.jpg"
    },
    {
        image: "/images/zuse_brands/lya.jpg"
    },
    {
        image: "/images/zuse_brands/pub.jpg"
    },
    {
        image: "/images/zuse_brands/warehouse.jpg"
    },
]


const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-lg-3 col-6" key={index}>
                    <div className="brand-grid">
                        <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
                    </div>
                </div>
            ))}
        </>
    )
}

export default BrandItem;