import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const AboutFour = () => {
	return (
		<div className="section section-padding case-study-featured-area">
			<div className="container">
				<div className="row">
					<div className="col-xl-7 col-lg-6">
						<div className="case-study-featured-thumb text-start">
							<img
								src={process.env.PUBLIC_URL + "/images/zuse/banner2.jpg"}
								alt="travel"
							/>
						</div>
					</div>
					<div className="col-xl-5 col-lg-6">
						<div className="case-study-featured">
							<div className="section-heading heading-left">
								<span className="subtitle">Who we are</span>
								<h2 className="title">Exceptional value and quality</h2>
								<p>
									At Zuse Technologies, we take pride in our ability to deliver
									exceptional value and quality. Our customer-centric approach
									has earned us long-term relationships with our clients. We
									leverage the latest technologies and best practices to deliver
									reliable and cost-effective IT solutions that help our clients
									achieve their business goals.
								</p>
								<p>
									Further, we specialize in a wide range of technology-focused
									services, including systems and network administration and
									support, hardware troubleshooting and maintenance, and data
									and business intelligence platforms.
								</p>
							</div>
							<div className="case-study-counterup">
								<div className="single-counterup">
									<h2 className="count-number">
										<TrackVisibility once>
											{({ isVisible }) => (
												<span className="number count">
													{isVisible ? <CountUp end="50" duration={1} /> : null}
												</span>
											)}
										</TrackVisibility>
										<span className="symbol">+</span>
									</h2>
									<span className="counter-title">Employees</span>
								</div>
								<div className="single-counterup">
									<h2 className="count-number">
										<TrackVisibility once>
											{({ isVisible }) => (
												<span className="number count">
													{isVisible ? (
														<CountUp end="44" duration={1} />
													) : null}
												</span>
											)}
										</TrackVisibility>
										<span className="symbol">+</span>
									</h2>
									<span className="counter-title">
										Projects delivered so far
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutFour;
