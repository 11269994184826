import React, { useState } from "react";
import { useParams } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BlogData from "../data/blog/BlogData.json";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import BlogSidebar from "../component/blog/BlogSidebar";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import { FaPlay } from "react-icons/fa";
import FsLightbox from "fslightbox-react";
import BlogListOne from "../component/blog/BlogListOne";
import SplashCta from "./../component/cta/SplashCta";

const allBlogData = BlogData;

Node.current = 0;

const BlogDetails = () => {
	const params = useParams();
	const blogId = parseInt(params.id);

	const getBlogData = allBlogData.filter((blog) => blog.id === blogId);
	const detailsBlog = getBlogData[0];

	const [toggler, setToggler] = useState(false);

	return (
		<>
			<SEO title={detailsBlog.title} />
			<ColorSwitcher />
			<main className="main-wrapper">
				<HeaderOne />
				<BreadCrumbOne title={detailsBlog.title} page="Products" />
				<div className="section-padding-equal">
					<div className="container">
						<div className="row row-40">
							<div className="col-lg-8">
								<div className="single-blog">
									<div className="single-blog-content product-grid">
										<div className="post-thumbnail">
											<img
												src={`${process.env.PUBLIC_URL}/${detailsBlog.large_thumb}`}
												alt="Blog"
											/>

											{detailsBlog.format === "video" ? (
												<>
													<div className="popup-video">
														<button
															className="play-btn"
															onClick={() => setToggler(!toggler)}
														>
															<FaPlay />
														</button>
													</div>
													<FsLightbox
														toggler={toggler}
														sources={[
															"https://www.youtube.com/watch?v=1iIZeIy7TqM",
														]}
													/>
												</>
											) : (
												""
											)}
										</div>
										<div className="author">
											<div className="author-thumb">
												<img
													src={`${process.env.PUBLIC_URL}/${detailsBlog.author_avatar}`}
													alt="Blog Author"
												/>
											</div>
											<div className="info">
												<h6 className="author-name">
													{detailsBlog.author_name}
												</h6>
												<ul className="blog-meta list-unstyled">
													<li>{detailsBlog.post_date}</li>
													<li>{detailsBlog.read_time}</li>
												</ul>
											</div>
										</div>
										{detailsBlog.body.map((data, i) => (
											<div
												key={i}
												dangerouslySetInnerHTML={{ __html: data }}
											></div>
										))}

										<div className="row">
											{detailsBlog.features_img.map((img, i) => (
												<div className="col-6" key={i}>
													<div className="featured-img">
														<img
															src={process.env.PUBLIC_URL + img}
															alt="Blog"
														/>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
								{/* <BlogAuthor data={detailsBlog} /> */}
							</div>
							<div className="col-lg-4">
								<BlogSidebar />
							</div>
						</div>
					</div>
				</div>

				<div className="section section-padding-equal pt-0 related-blog-area">
					<div className="container">
						<div className="section-heading heading-left">
							<h3 className="title">Our Products</h3>
						</div>
						<div className="row g-0">
							<BlogListOne colSize="col-xl-6" itemShow="2" />
						</div>
					</div>
				</div>
				<SplashCta />
				<FooterOne parentClass="" />
			</main>
		</>
	);
};

export default BlogDetails;
