import React from "react";
import { useParams } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BcrumbBannerTwo from "../elements/breadcrumb/BcrumbBannerTwo";
import { slugify } from "../utils";
import ProjectData from "../data/project/ProjectData.json";
import Accordion from "react-bootstrap/Accordion";
import { FaCompress, FaCode, FaGlobe } from "react-icons/fa";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import SplashCta from "./../component/cta/SplashCta";

const allProjectData = ProjectData;

const ProjectDetails = () => {
	const params = useParams();
	const projectSlug = params.slug;

	const getProjectData = allProjectData.filter(
		(data) => slugify(data.title) === projectSlug
	);
	const detailsProject = getProjectData[0];

	const openLinkInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) {
			newWindow.opener = null
		}
	}
	return (
		<>
			<SEO title="Project Details" />
			<ColorSwitcher />
			<main className="main-wrapper">
				<HeaderOne />
				<BcrumbBannerTwo
					title={detailsProject.title}
					paragraph={detailsProject.excerpt}
					mainThumb={detailsProject.image}
				/>
				<section className="section-padding single-portfolio-area">
					<div className="container">
						<div className="row">
							<div className="col-lg-5">
								<div className="section-heading heading-left mb-0">
									<span className="subtitle">
										{detailsProject.category.map((cat, i) => (
											<span key={i}>{cat}</span>
										))}
									</span>
									<h3 className="title">{detailsProject.title}</h3>
								</div>
								{detailsProject.body.map((para, index) => (
									<p key={index} dangerouslySetInnerHTML={{ __html: para }}></p>
								))}
								{detailsProject.link.map((link, index) => (
									<p key={index}onClick={() => openLinkInNewTab(link)} className="axil-btn btn-fill-primary">
										View Live Site
									</p>
								))}
							</div>
							<div className="col-lg-6 offset-xl-1">
								<div className="why-choose-us">
									<div className="section-heading heading-left">
										<h3 className="title">We delivered</h3>
										<p>
											{detailsProject.brief.map((para, index) => (
												<p
													key={index}
													dangerouslySetInnerHTML={{ __html: para }}
												></p>
											))}
										</p>
									</div>
									<Accordion defaultActiveKey="1">
										<Accordion.Item eventKey="1">
											<Accordion.Header>
												<FaCompress /> Strategy
											</Accordion.Header>
											<Accordion.Body>
												{detailsProject.stratergy.map((para, index) => (
													<p
														key={index}
														dangerouslySetInnerHTML={{ __html: para }}
													></p>
												))}
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>
												<FaCode /> Design
											</Accordion.Header>
											<Accordion.Body>
												{detailsProject.design.map((para, index) => (
													<p
														key={index}
														dangerouslySetInnerHTML={{ __html: para }}
													></p>
												))}
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>
												<FaGlobe /> Development
											</Accordion.Header>
											<Accordion.Body>
												{detailsProject.development.map((para, index) => (
													<p
														key={index}
														dangerouslySetInnerHTML={{ __html: para }}
													></p>
												))}
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
							</div>
						</div>
					</div>
				</section>
				<SplashCta />
				<FooterOne parentClass="" />
			</main>
		</>
	);
};

export default ProjectDetails;
