import React from 'react';
import PropTypes from 'prop-types';

const SEO = ({ title, description, image, url, author, keywords }) => {
    const siteName = 'Zuse Technologies';
    const twitterHandle = '@ZuseTechnologies';
    const siteUrl = 'https://zuse.lk';

    const defaultDescription =
        "Zuse Technologies is your go-to partner for innovative and effective IT product development. We leverage the latest technologies and best practices to deliver reliable and cost-effective IT solutions that help our clients achieve their business goals.";

    const defaultKeywords =
        "Innovative IT Solutions, Cutting-edge Technology, IT Product Development, Reliable IT Services, Cost-effective Solutions, Technology Partner, Customized IT Solutions, Business-driven Technology, Advanced Technology Practices, IT Consultancy Services, Software Development Expertise, Strategic IT Planning, Future-proof Solutions, Client-centric Approach, Digital Transformation Services";

    return (
        <>
            <meta charSet='utf-8' />
            <title>{title ? `${title} | ${siteName}` : siteName}</title>
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            <meta name='description' content={description || defaultDescription} />
            <meta name='keywords' content={keywords || defaultKeywords} />

            {/* Open Graph (OG) tags */}
            <meta property='og:type' content='website' />
            <meta property='og:title' content={title || siteName} />
            <meta property='og:description' content={description || defaultDescription} />
            <meta property='og:image' content={image || 'https://zuse.lk/images/zuse/banner3.jpg'} />
            <meta property='og:url' content={url || siteUrl} />
            <meta property='og:site_name' content={siteName} />
            <meta property='og:image:width' content='1200' />
            <meta property='og:image:height' content='630' />

            {/* Twitter Card tags */}
            <meta name='twitter:card' content='https://zuse.lk/images/zuse/banner3.jpg' />
            <meta name='twitter:site' content={twitterHandle} />
            <meta name='twitter:creator' content={author || twitterHandle} />
            <meta name='twitter:title' content={title || siteName} />
            <meta name='twitter:description' content={description || defaultDescription} />
            <meta name='twitter:image' content={image || 'https://zuse.lk/images/zuse/banner3.jpg'} />

            {/* Canonical link */}
            <link rel='canonical' href={url || siteUrl} />
        </>
    );
};

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string,
    author: PropTypes.string,
    keywords: PropTypes.string,
};

export default SEO;
