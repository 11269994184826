import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
import ProjectData from "../../data/project/ProjectData.json";
import ServiceData from "../../data/service/ServiceMain.json";
import ProductData from "../../data/blog/BlogData.json";
import { slugify } from '../../utils';

const getServiceData = ServiceData;
const getProductData = ProductData;
const getProjectData = ProjectData;

const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                    <Link to="/">Home</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="/about-us">About Us</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="/team">Our Team</Link>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Services <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        {getServiceData.map((data, index) => (
                            <li key={index}>
                                <Link to={process.env.PUBLIC_URL + `/service-details/${slugify(data.title)}`}>{data.title}</Link>
                            </li>
                        ))}
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Portfolio <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        {getProjectData.map((data, index) => (
                            <li key={index}>
                                <Link to={process.env.PUBLIC_URL + `/project-details/${slugify(data.title)}`}>{data.title}</Link>
                            </li>
                        ))}
                    </ul>
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Products <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        {getProductData.map((data, index) => (
                            <li key={index}>
                                <Link to={process.env.PUBLIC_URL + `/products/${slugify(data.id)}`}>{data.title}</Link>
                            </li>
                        ))}
                    </ul>
                </li>
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;