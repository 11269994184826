import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
import AboutThree from '../component/about/AboutThree';
import AboutFour from '../component/about/AboutFour';
import AboutFive from '../component/about/AboutFive';
import SplashCta from '../component/cta/SplashCta';
import BreadCrumbOne from './../elements/breadcrumb/BreadCrumbOne';


const AboutUs = () => {

    return (
        <>
        <SEO title="About us" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
				<BreadCrumbOne title="About Us" page="About Us"/>
                <AboutFour />
                <AboutThree />
                <AboutFive />
                {/* <ProcessOne /> */}
                <SplashCta />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;